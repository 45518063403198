.content {
    margin: 2rem auto;
    margin-bottom: 4rem;
    width: 65%;
}

.content-m {
    margin: 2rem auto;
    margin-bottom: 4rem;
    width: 98%;
}

.clogs-check {
    cursor: pointer;
}

.edit_content {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
}

.edit_html {
    overflow: auto;
}